import api from '@axios';

class VoucherService {

  async fetchTable() {
    try {
      const { data } = await api.get('/invoices/codesTable')
      return data
    } catch (err) {
      return []
    }
  }
  
  createVoucher(params) {
    return api.get('/invoices/createCode', { params })
	}
}

export default new VoucherService();
