<template>
  <section id="vouchersPage">
    <b-row>
      <b-col class="mb-2">
        <b-card class="voucher-card mb-0">
          <b-card-sub-title class="mb-2">Create voucher / gift code</b-card-sub-title>
          <validation-observer ref="formValidation">
            <b-form>
              <b-card-text class="mb-2">
                <b-form-group
                  label="Amount:"
                  label-for="amountInput"
                  label-class="form-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Amount"
                    rules="required"
                  >
                    <b-form-input
                      id="amountInput"
                      name="amount"
                      v-model="draftVoucher.amount"
                      type="number"
                      min="0"
                      placeholder="Enter amount"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-text>
              <b-card-text class="mb-2">
                <b-form-group
                  label="Extra info:"
                  label-for="basicInput"
                  label-class="form-label"
                >
                  <b-form-textarea
                    id="textarea-default"
                    name="extra_info"
                    v-model="draftVoucher.extra_info"
                    placeholder="Enter info"
                    rows="2"
                  />
                </b-form-group>
              </b-card-text>
              <b-button
                variant="outline-success"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                @click="createVoucher"
              >
                Create
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col class="mb-2">
        <b-card
          v-if="activeVoucher"
          class="voucher-card mb-0 h-100"
        >
          <b-button
            variant="flat"
            class="btn-icon btn-close"
            @click="activeVoucher = null"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-card-sub-title>Last created voucher</b-card-sub-title>
          <b-card-title class="voucher-amount text-center mt-5">{{ activeVoucher.amount }}{{ activeVoucher.currency || '$' }}</b-card-title>
          <VoucherClipboard :code="activeVoucher.code" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <VouchersTable ref="table" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VoucherClipboard from './VoucherClipboard.vue'
import VouchersTable from './VouchersTable.vue'
import VoucherService from '@services/voucher.service'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    VoucherClipboard,
    VouchersTable,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    draftVoucher: {},
    activeVoucher: null,
    required,
  }),
  methods: {
    validateForm() {
      return this.$refs.formValidation.validate()
    },

    confirmCreation() {
      return this.$bvModal
        .msgBoxConfirm('Are you sure that you want to create a new voucher?', {
          title: 'Please confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },

    async createVoucher() {
      const valid = await this.validateForm()
      if (! valid) return
      const confirmed = await this.confirmCreation()
      if (! confirmed) return

      try {
        const { data } = await VoucherService.createVoucher(this.draftVoucher)
        this.handleResponse(data)
      } catch (error) {
        this.notify({
          variant: 'danger',
          text: 'An error has occured and the gift code could not be generated.',
        })
        return
      }
      this.draftVoucher = {}
      this.$refs.formValidation.reset()
    },

    handleResponse(data) {
      if (data && data.success) {
        this.activeVoucher = data
        this.notify({
          variant: 'success',
          text: 'Gift code was successfully generated.',
        })
        this.$refs.table.fetch()
      } else {
        this.notify({
          variant: 'danger',
          text: 'An error has occured and the gift code could not be generated.',
        })
      }
    },

    notify({ variant = 'success', text = '' } = {}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
  },

  mounted() {
    window.__V__ = this
  }
};
</script>

<style lang="scss">
.voucher-card {
  .form-label {
    font-size: 1.285rem;
    font-weight: 500;
  }

  .voucher-amount {
    font-size: 2rem;
    font-weight: 600;
  }

  .btn-close {
    position: absolute !important;
    top: 10px;
    right: 10px;
  }
}
</style>
